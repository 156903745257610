.fluid-image-max-width {
    max-width: rem(1600);
}

.font-color {
    &-black {
        h1,
        h2,
        h3,
        h4,
        p,
        span {
            color: $black !important;
        }
    }

    &-white {
        h1,
        h2,
        h3,
        h4,
        p,
        span {
            color: $white !important;
        }
    }
}

.header_banner {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    height: 100%;

    @include media-breakpoint-down(md) {
        position: relative;
        left: 0;
        transform: translateX(0);
    }
}

.cc-quotemarks {
    height: rem(40);
    width: rem(34);
    background-size: 100%;
    background-image: url('../../images/quotemarks.svg');
    background-repeat: no-repeat;
}

.reverse-children-mobile {
    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column-reverse !important;
    }
}

.red-line {
    width: 5rem;
    height: 0.25rem;
    background: $nb-red;
}

.disclaimer {
    bottom: 0;
}

.didot {
    font-family: $nb-page-heading-font;
    font-size: rem(56);
    line-height: rem(60);

    html[lang="ja"] & {
        font-size: rem(38);
        line-height: rem(42);
    }
}

.comp-j {
    height: 100%;
    @include media-breakpoint-up(lg) {
        height: auto;
    }
}
