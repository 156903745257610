@import '~base/login';
@import './global/variables';
@import './global/colors';
@import "./global/spacers";
@import './mixins/util';

.alert-description {
    color: $nb-red;
}

.checkout-login,
.nb-register-page {
    .cont-adjustment {
        @include media-breakpoint-down(md) {
            flex-direction: column-reverse;
        }
    }

    .password-list-style {
        list-style: disc;
    }

    .create-acc,
    .create-account-link {
        line-height: $font-size20;
    }

    .create-account,
    .have-account {
        @include media-breakpoint-down(md) {
            padding-left: rem(16);
        }
    }

    .card-body {
        .login-form-nav {
            .login {
                .form-group:last-child {
                    @include media-breakpoint-down(md) {
                        margin-bottom: rem(16);
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            max-width: 100%;
            width: 100%;
            margin: 0 auto;
        }

        .registration {
            button {
                margin-bottom: 0.5rem;
            }

            .submit-button,
            .oauth-facebook {
                display: inline-flex;
                align-items: center;
                justify-content: center;

                @include media-breakpoint-down(md) {
                    height: 3rem;
                }
            }
        }

        .custom-checkbox {
            .custom-control-label {
                font-weight: $fontweight-semibold;
                white-space: nowrap;
            }
        }
    }

    .tab-content {
        .login-submit {
            width: 12rem;

            @include media-breakpoint-down(lg) {
                width: 100%;
                margin: 0 auto;
            }
        }
    }

    .body-semibold {
        font-weight: $fontweight-semibold !important;

        &.terms {
            @include media-breakpoint-down(md) {
                font-weight: $fontweight-regular !important;

                span,
                span .body-semibold {
                    font-weight: $fontweight-regular !important;
                }
            }
        }
    }

    .create-account-link {
        @include media-breakpoint-down(sm) {
            width: auto; // made this change as the layout was breaking on mobile
        }
    }

    .nav-tabs {
        border-bottom: 1px solid $nb-black;

        .nav-item {
            @include media-breakpoint-down(md) {
                width: 50%;
                text-align: center;
            }
        }

        .nav-link {
            font-size: $font-size14 !important;
            font-weight: $fontweight-semibold;

            &.active {
                color: $nb-red !important;
                border-bottom: 0.25rem solid $nb-red;
                margin-bottom: rem(-1);
            }
        }
    }

    .form-control-label {
        &::before {
            content: '*' !important;
        }
    }

    .hide-show {
        position: absolute;
        right: 0;
        top: 1.5rem;
    }

    .hide-password {
        display: none;
    }

    .oauth-facebook {
        white-space: unset;
        color: $white !important;
        background-color: $blumine !important;
        padding: 0 1.4rem;

        @include media-breakpoint-down(sm) {
            width: 100%;
            height: 3rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0;
        }
    }

    .form-group {
        .custom-control-label {
            left: 5%;
            top: 10%;
            font-size: $font-size14;
            line-height: $font-size20;
            color: $black !important;
            text-transform: initial;

            @include media-breakpoint-down(sm) {
                left: 7%;
            }

            &::after {
                background-color: $white;
            }
        }

        .terms-error,
        .forgot-pwd {
            font-size: $font-size10;
            color: $nb-red;
            text-decoration: underline;
        }
    }

    .have-account {
        display: none;

        .have-account-link {
            line-height: $font-size20;
            font-weight: $fontweight-medium;
            @include media-breakpoint-down(sm) {
                width: auto;
                display: inline-block;
            }
        }
    }

    .password-requirement {
        font-size: $font-size12;

        .requirements-list {
            list-style: disc;
        }
    }

    .nb-reawrd-msg {
        font-size: $font-size12;
        color: $black;
    }

    .fb-login {
        .oauth-facebook {
            border: 1px solid transparent !important;

            .facebook-white {
                margin-right: rem(8);
            }
        }
    }
}

.nb-modal,
.reset-password-page {
    .reset-password-text {
        color: $black;
        font-size: $font-size24;
        font-weight: $fontweight-bold;
        letter-spacing: 0;
        line-height: rem(32);
    }

    .enter-email-address-text {
        color: $black;
        letter-spacing: 0;
        line-height: rem(20);
        margin-bottom: $nb-spacing-7;

        @include media-breakpoint-down(sm) {
            margin-bottom: rem(44); // added this as we dont have spacer class for 44px but mobile design had margin of 44px.
        }
    }

    .reset-password-input-container {
        margin-bottom: $nb-spacing-5;
        @include media-breakpoint-down(sm) {
            margin-bottom: $nb-spacing-4;
        }
    }
}

.free-shipping-login {
    border-radius: 0.25rem;
    background-color: $gallery-solid !important;
}

// New Login Page Css
.login-page {
    .have-account {
        display: none;
    }

    .nav-tabs {
        border-radius: 4px;
        border: 2px solid $ghost-white;

        .nav-item {
            flex: 1 1;

            &:first-of-type {
                .nav-link {
                    border-radius: 4px 0 0 4px;
                }
            }

            &:last-of-type {
                .nav-link {
                    border-radius: 0 4px 4px 0;
                }
            }
        }

        .nav-link {
            background-color: $ghost-white;

            &.active {
                background-color: $white;
                font-weight: 500;
                color: $black;
            }
        }
    }

    .card {
        border-radius: 4px;
        border: 1px solid $silver-dark-gray;
        padding: rem(16);
        color: $black;
        display: flex;
        gap: 12px;

        h3 {
            font-size: $font-size18;
            line-height: 19px;
        }

        &-body {
            padding: 12px 0;
        }

        .login-divider {
            border-top: 1px solid $nb-grey;
            color: $nb-grey;
            text-align: center;
            margin-top: 32px;

            span {
                position: relative;
                top: -12px;
                display: inline-block;
                background: #fff;
                padding: 0 16px;
                font-weight: 500;
                text-transform: capitalize;
            }
        }

        // FORM STYLES
        &-form {
            .button-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 8px;
                margin-top: 16px;

                button,
                a[type=button] {
                    border-radius: 4px;
                    flex-grow: 1;
                    height: 44px;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                }

                button.button-secondary,
                a[type=button].button-secondary {
                    border-color: $silver-dark-gray;
                    color: $nb-black !important;
                    border-width: 1px;

                    &:hover,
                    &:not(:disabled):not(.disabled):active,
                    &:focus {
                        box-shadow: inset 0 0 0 0.125rem $silver-dark-gray;
                    }
                }
            }

            .font-body.error {
                color: $nb-red;
                padding: 4px 12px;
                border: 1px solid $nb-red;
                border-radius: 4px;
                margin: 8px;
                text-align: center;
                background: rgba(207, 10, 44, 0.2);
            }

            .card-body {
                display: flex;
                flex-direction: column;
                gap: 12px;

                .input-wrapper {
                    position: relative;
                    margin: 0;
                }

                .row {
                    margin: 0;
                    gap: 12px;
                }

                .col-12,
                .col-lg-6,
                .col-lg-4 {
                    padding: 0;
                }

                .col-lg-6 {
                    flex: none;
                    width: calc(50% - 6px);
                }

                .col-lg-4 {
                    flex: none;
                    width: calc(33.33333% - 8px);
                }

                .form-group {
                    margin-bottom: 0;
                }

                .form-group input:not([type=radio]):not([type=checkbox]),
                .form-group select {
                    border: 1px solid $silver-dark-gray;
                    border-radius: 4px;
                    padding: 28px 11px 12px;
                    height: 58px;
                    min-height: 0;
                    line-height: inherit;
                    font-size: $font-size16;

                    &.is-invalid {
                        border: 1px solid $nb-dark-red;
                        background-image: none;
                    }

                    &:focus ~ label,
                    &:not(:placeholder-shown) ~ label {
                        color: $gray-blue;
                        font-size: $font-size14;
                        top: 12px;
                        line-height: 1;
                    }
                }

                .form-group select {
                    padding: 24px 11px 12px;
                }

                .invalid-feedback {
                    font-size: $font-size14;
                    margin: 0;
                    color: $nb-dark-red;
                }

                input[type="date"]:not(.has-no-value) {
                    &::-webkit-datetime-edit {
                        opacity: 1;
                    }
                }

                input[type="date"].has-no-value {
                    &::-webkit-datetime-edit {
                        opacity: 0;
                    }
                }

                .create-account-nb {
                    text-transform: lowercase;

                    &::first-letter {
                        text-transform: capitalize;
                    }
                }

                label {
                    font-size: $font-size14;
                    color: $gray-blue;
                    position: absolute;
                    z-index: 1;
                    left: 12px;
                    top: 12px;
                    font-weight: 300;
                    line-height: 1;
                    text-transform: lowercase;

                    &::first-letter {
                        text-transform: capitalize;
                    }
                }

                .font-body:not(.text-main) {
                    color: $gray-blue;
                }

                .custom-checkbox label {
                    top: 4px;
                    left: 24px;
                    min-width: 100px;
                    color: $nb-black;
                    font-weight: 500;

                    &::after,
                    &::before {
                        top: 0;
                        border-radius: 4px;
                    }

                    + div {
                        padding: 2px;
                    }
                }

                .custom-checkbox .custom-control-input:not(:checked) + .custom-control-label::after {
                    border: 1px solid $black;
                    background-image: none;
                }

                .hide-show {
                    position: absolute;
                    z-index: 1;
                    right: 12px;
                    top: 18px;
                }

                .hide-password {
                    display: none;
                }

                .list-style-disc {
                    font-size: $font-size14;
                    list-style-type: disc;

                    li {
                        color: $nb-grey;
                    }
                }

                .requirements-list {
                    list-style-type: disc;
                    color: $gray-blue;
                }
            }
        }
    }
}

// Require Email Styles
.account-wrapper.change-email {
    width: 450px;
    margin-top: 24px;
    min-height: 50vh;

    .text-wrapper {
        text-align: center;
        margin-bottom: 16px;
    }

    .card-footer {
        margin-top: 32px;
    }
}
