@import './productCardsCustom';
@import './dynamicShippingHeader';

// Variable definition for landscape orientation
$landscape-orientation: ('orientation: landscape');

#inStoreInventoryModal,
#storeFinderModal,
.shipping-section {
    .modal-header {
        padding: 0;
        border: none;

        .close-store-modal {
            top: 1.2rem;
            z-index: 9;
            right: 1.4rem;
            background: #f2f2f3;
            border: 0;
            padding: 5px 8px 7px !important;
            margin-right: 0.1rem;
            border-radius: 3px;
        }
    }

    .modal-dialog {
        @include media-breakpoint-up(lg) {
            max-width: 400px;
        }
    }

    .store-locator-container {
        .card,
        .card-header {
            border: none;
            background-color: $white;
            top: 11px;
        }

        .form-check.custom-radio {
            .form-check-label {
                &::after {
                    top: 3px;
                    left: -10px;
                }
            }
        }
    }

    .zip-field {
        .form-group {
            margin-bottom: 0;
        }

        input {
            font-size: $font-size16;
            padding-right: rem(38);
            border-radius: 4px;
        }

        input:not(.is-invalid) {
            border: 1px solid #abb0b4;
        }

        .btn-storelocator-search {
            right: 0;
        }
    }

    select.radius {
        font-size: 16px;
    }

    .store-results-wrapper {
        padding: 10px;
    }

    .radius-selector {
        position: relative;

        .down-icon {
            position: absolute;
            right: 20px;
            pointer-events: none;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .bopis-header {
        text-align: center;
        display: flex;
        position: relative;
        top: -21px;
        left: 7px;
    }

    #store-postal-code {
        font-size: $font-size16;
        font-weight: $fontweight-semibold;
        line-height: 18px;
        color: $nb-black;
    }

    #store-postal-code {
        &::placeholder {
            color: $silver-grey;
        }
    }

    .custom-select {
        &:focus {
            border-color: $dark-grey;
        }
    }

    .results {
        top: -16px;
        display: grid;
        position: relative;
        gap: 16px;
    }

    .custom {
        flex-direction: column;
        justify-content: center;
    }

    .custom-hr {
        border-top: 1px solid #ccc;
    }

    .result-store-count-text {
        display: flex;
        position: relative;
        right: 9px;
        color: gray;
        font-weight: $fontweight-regular;
        font-size: $font-size16;
    }

    .store-locator-heading {
        margin-bottom: -14px;
    }
}

#inStoreInventoryModal,
#storeFinderModal {
    .update-store-area {
        bottom: 0;
        left: 0;
        background: $white;
    }

    .store-result {
        padding: 1rem;
        background: $nb-grey-lighter;
        border-radius: 4px;
        margin-bottom: 0.5rem;
        top: -19px;
        display: block;
        position: relative;

        .store-ats {
            color: $green;
            margin-bottom: 1rem;

            .store-ats-low {
                color: $orange;
            }

            .store-ats-none {
                color: $nb-dark-red;
            }
        }

        .store-details,
        .store-map {
            color: #5c6268;
        }

        .custom-address {
            line-height: 1.5rem;
        }

        .store-name-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            width: 100%;
            gap: 1rem;
        }

        .store-name {
            line-height: 1.2rem;
            color: #151415;
            font-size: $font-size16;
            font-weight: $fontweight-semibold !important;
            flex-grow: 1;
            margin-right: 2rem;
        }

        .select-store {
            color: #151415;
        }

        .store-details {
            display: block;
            position: relative;
            line-height: 1.5rem;
        }

        .storelocator-phone {
            color: $black;
        }

        .store-info {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
        }

        .store-distance {
            white-space: nowrap;
            margin-left: 1rem;
            align-self: flex-start;
            text-align: right;
        }
    }
}

.shipping-method-card {
    border-radius: 5px;
    background-color: $light-grey;
    padding: 24px;
    font-size: $font-size12;

    .method {
        display: flex;
        font-size: $font-size14;
        font-weight: $fontweight-bold;
    }

    &.pre-order,
    &.back-order {
        background-color: #3e3e3e;
        color: #fff;
        font-weight: $fontweight-regular;

        .method {
            font-weight: $fontweight-regular;
        }

        .preorder-label {
            font-weight: $fontweight-bold;
        }
    }

    .delivery-estimate {
        margin-left: -4px;
    }
}

.bopis-policy {
    a {
        color: $red;
        font-weight: $fontweight-semibold;
        text-decoration: underline;
    }
}

.product-details {
    hr.no-top-bottom-margin {
        margin: 0 1.5rem;
    }

    #shipping-method {
        .display-name {
            font-weight: $fontweight-semibold;
        }

        &.selected {
            background: $nb-grey-light;
            border-color: $nb-black !important;
        }
    }

    #bopis-method {
        &.disable {
            .custom-radio .form-check-label::before {
                background-color: transparent;
            }

            .display-name {
                color: $dark-grey;
            }

            .card-body {
                color: $dark-grey;

                .store-map {
                    color: $dark-grey;
                }

                .store-ats {
                    display: none;
                }
            }
        }

        &.selected {
            background: $nb-grey-light;
            border-color: $nb-black !important;
        }
    }
}

// CHO-570 (desktop) store finder modal
#storeFinderModal.modal:not(.nb-modal) {
    @include media-breakpoint-up(lg) {
        .modal-dialog {
            display: flex;
            justify-content: center;
            position: relative;
            margin: 0;
            width: 600px;
            max-width: 600px;
            height: auto;
            max-height: 95vh;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .modal-content {
            border-radius: 6px;
            background-color: $modal-content-bg;
            height: auto;
        }

        .close-store-modal {
            right: 1.4rem;
        }

        #store-postal-code {
            width: 100%;
            height: 58px;
            position: relative;
        }

        .btn-storelocator-search {
            display: flex;
        }

        .js-search-store-coordinates {
            position: relative;
            width: 100%;
            height: 58px;
            align-items: center;
            justify-content: center;
            flex: 1;
        }

        .custom {
            position: relative;
        }

        .custom-vr-top {
            height: 15px;
            border: 1px solid #ccc;
            position: relative;
            top: -4px;
        }

        .custom-vr-bottom {
            height: 15px;
            border: 1px solid #ccc;
            position: relative;
            top: 3px;
        }
    }

    .store-locator {
        .d-flex {
            display: flex;
        }

        .align-items-center {
            align-items: center;
            flex: 0;
        }

        .zip-field {
            position: relative;
            flex-direction: row;
            justify-content: space-between;
            gap: 0.5rem;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                align-items: stretch;
                width: 100%;

                .btn-storelocator-search {
                    margin-bottom: 1rem;
                }

                .form-group {
                    width: 100%;
                    position: relative;

                    .btn-storelocator-search {
                        color: $nb-grey-light;
                    }
                }
            }
        }

        .form-group {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            flex: 1;

            .btn-storelocator-search {
                display: flex;
                top: 35%;
                right: 12px;
            }

            .nb-button.location-button {
                width: 256px !important;
                min-width: unset !important;
            }
        }

        .font-body-small {
            font-size: 0.9rem;
            color: #5c6268;
            left: 0;
            position: relative;
            top: -2px;
        }
    }
}

// CHO-570 (mobile) store finder modal
#storeFinderModal.modal {
    @include media-breakpoint-down(md) {
        &#storeFinderModal {
            overflow-y: hidden;
            transition: all 0.3s ease-in-out;

            &.show {
                .modal-dialog {
                    right: 0;
                    bottom: 0;

                    &.stores-searched {
                        transform: translateY(10%);
                    }

                    @media ($landscape-orientation) {
                        transform: translateY(10%);
                    }
                }
            }

            .modal-dialog {
                transform: translateY(70%);
                border-radius: 1rem 1rem 0 0;
                overflow: hidden;
                right: 0;
                bottom: 0;
                height: 105vh; // Height for medium screens and below

                .modal-content {
                    overflow-x: hidden;
                    overflow-y: auto;
                    padding-bottom: 3rem; //44px menu + 6px bottom spacing
                }
            }

            .card.results-card {
                margin-bottom: 5rem;
            }

            #store-postal-code {
                width: 100%;
                height: 58px;
            }

            .js-search-store {
                width: 100%;
            }

            .font-body-small {
                font-size: 0.875rem;
                color: #5c6268;
            }

            .custom-hr {
                border-top: 1px solid #ccc;
                width: 100%;
                height: 1px;
            }
        }

        .font-body-small {
            font-size: 0.9rem;
            color: #5c6268;
            left: 0;
            position: relative;
            top: -6px;
        }
    }

    @include media-breakpoint-down(sm) {
        .modal-dialog { height: 85vh; }  // Height for small screens and below
    }
}
