@import '../login';

$sidenav-width-xs: 100%;
$sidenav-width-md: 60%;
$sidenav-width-lg: 35%;  // changed as part of SFRA-1174
$sidenav-width-xl: 33%;

$modal-content-bg: #fff;
$max: 1366px;
$portrait: portrait;
$landscape: landscape;
$background-color: #efefef;
$maxheight-800: 800px;

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff

.modal-open {
    // Kill the scroll on the body
    overflow: hidden;

    @include media-breakpoint-down(md) {
        position: fixed;
        -webkit-overflow-scrolling: touch;
    }

    .modal {
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.modal.fade:not(.nb-modal).right .modal-dialog {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition: 0.3s;
    -webkit-transition: 0.3s;
}

// Container that the modal scrolls within
.modal:not(.nb-modal) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-modal;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    max-width: 100%;

    // Prevent Chrome on Windows from adding a focus outline. For details, see
    // https://github.com/twbs/bootstrap/pull/10951.

    outline: 0;
    // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
    // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
    // See also https://github.com/twbs/bootstrap/issues/17695

    .modal-dialog {
        position: fixed;
        margin: auto;
        // allow clicks to pass through for custom click handling to close modal
        pointer-events: none;
        height: 100%;
        width: $sidenav-width-xs;
        right: -$sidenav-width-xs;
        transform: translate3d(0, 0, 0);
        transition: all 0.3s;

        @include media-breakpoint-up(md) {
            width: $sidenav-width-md;
            right: -$sidenav-width-md;
        }

        @include media-breakpoint-up(lg) {
            width: $sidenav-width-lg;
            right: -$sidenav-width-lg;
        }

        // @include media-breakpoint-up(xl) {
        //     width: $sidenav-width-xl;
        //     right: -$sidenav-width-xl;
        // }

        // NB Adyen Customization: Custom styles for Adyen 3DS challenge modal
        &.adyenModal {
            pointer-events: auto;
            border-radius: 0.1875rem;
            border: 1px solid black;
            background-color: white;
        }
    }

    &.zoom-modal {
        .modal-dialog {
            width: 100%;
        }

        .modal-body {
            width: 100%;
        }
    }

    &.show {
        .modal-dialog {
            right: 0;
        }
    }

    .modal-dialog-scrollable {
        display: flex; // IE10/11
        max-height: calc(100% - #{$modal-dialog-margin * 2});

        .modal-content {
            max-height: calc(100vh - #{$modal-dialog-margin * 2}); // IE10/11
            overflow: hidden;
        }

        .modal-header,
        .modal-footer {
            flex-shrink: 0;
        }

        .modal-body {
            overflow-y: auto;
        }
    }

    .modal-dialog-centered {
        display: flex;
        align-items: center;
        min-height: calc(100% - #{$modal-dialog-margin * 2});

        // Ensure `modal-dialog-centered` extends the full height of the view (IE10/11)
        &::before {
            display: block; // IE10
            height: calc(100vh - #{$modal-dialog-margin * 2});
            content: "";
        }

        // Ensure `.modal-body` shows scrollbar (IE10/11)
        &.modal-dialog-scrollable {
            flex-direction: column;
            justify-content: center;
            height: 100%;

            .modal-content {
                max-height: none;
            }

            &::before {
                content: none;
            }
        }
    }

    // Actual modal
    .modal-content {
        height: 100%;
        overflow-y: auto;
        border-radius: 0;
        border: none;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
        // counteract the pointer-events: none; in the .modal-dialog
        color: $modal-content-color;
        pointer-events: auto;
        background-color: $modal-content-bg;
        background-clip: padding-box;
        // Remove focus outline from opened modal
        outline: 0;
    }

    // Modal background
    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        z-index: $zindex-modal-backdrop;
        width: 100vw;
        height: 100vh;
        background-color: $modal-backdrop-bg;

        // Fade for backdrop
        &.fade {
            opacity: 0;
        }

        &.show {
            opacity: $modal-backdrop-opacity;
        }
    }

    // Modal header
    // Top section of the modal w/ title and dismiss
    .modal-header {
        display: flex;
        align-items: flex-start; // so the close btn always stays on the upper right corner
        justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
        padding: $modal-header-padding;

        .close {
            // auto on the left force icon to the right even when there is no .modal-title
            margin: 0 (-$modal-header-padding-x) (-$modal-header-padding-y) auto;
        }
    }

    // Title text within header
    .modal-title {
        margin-bottom: 0;
        line-height: $modal-title-line-height;
    }

    // Modal body
    // Where all modal content resides (sibling of .modal-header and .modal-footer)
    .modal-body {
        position: relative;
        // Enable `flex-grow: 1` so that the body take up as much space as possible
        // when should there be a fixed height on `.modal-dialog`.

        flex: 1 1 auto;
        padding: $modal-inner-padding;

        .btn-outline-danger {
            border: solid 0.125rem $nb-red;
            font-weight: $fontweight-bold;
        }

        @include media-breakpoint-down(md) {
            .hidden-md-down {
                display: block !important;
            }
        }
    }

    // Footer (for actions)
    .modal-footer {
        display: flex;
        align-items: center; // vertically center
        justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
        padding: $modal-inner-padding;
        border-top: $modal-footer-border-width solid $modal-footer-border-color;
        @include border-bottom-radius($modal-content-border-radius);

        // Easily place margin between footer elements
        > :not(:first-child) {
            margin-left: 0.25rem;
        }

        > :not(:last-child) {
            margin-right: 0.25rem;
        }
    }

    // Measure scrollbar width for padding body during modal show/hide
    .modal-scrollbar-measure {
        position: absolute;
        top: -9999px;
        width: 50px;
        height: 50px;
        overflow: scroll;
    }

    // Scale up the modal

    @include media-breakpoint-up(sm) {
        .modal-dialog-scrollable {
            max-height: calc(100% - #{$modal-dialog-margin-y-sm-up * 2});

            .modal-content {
                max-height: calc(100vh - #{$modal-dialog-margin-y-sm-up * 2});
            }
        }

        .modal-dialog-centered {
            min-height: calc(100% - #{$modal-dialog-margin-y-sm-up * 2});

            &::before {
                height: calc(100vh - #{$modal-dialog-margin-y-sm-up * 2});
            }
        }

        .modal-content {
            @include box-shadow($modal-content-box-shadow-sm-up);
        }

        .modal-sm {
            max-width: $modal-sm;
        }
    }

    @include media-breakpoint-up(lg) {
        .modal-lg,
        .modal-xl {
            max-width: $modal-lg;
        }
    }

    @include media-breakpoint-up(xl) {
        .modal-xl {
            max-width: $modal-xl;
        }
    }
}

// Shell div to position the modal with bottom padding

.nb-modal.modal.show {
    .nb-modal-container {
        transform: translateX(0%);
    }
}

.nb-modal {
    * {
        box-sizing: border-box;
    }

    .nb-modal-container {
        transition: transform 0.5s cubic-bezier(0.64, 0.04, 0.35, 1);
        transform: translateX(100%);

        .size-chart-container {
            .how-to-measure {
                padding: 0 !important;
            }

            .tab-layout {
                .nav-tabs {
                    background-color: $background-color;
                    text-align: left;
                    width: fit-content;
                    overflow-x: visible;
                    padding: 0.25rem;

                    .nav-item {
                        padding: 0 !important;
                        min-width: rem(110);

                        @include media-breakpoint-up(xl) {
                            min-width: rem(200);
                        }

                        .nav-link {
                            padding: rem(4) rem(10) !important;
                            font-size: 16px;

                            &.active {
                                border-radius: rem(2);
                                border: #a4adb6 solid 1px;
                            }
                        }
                    }
                }

                .tab-content {
                    padding-top: 0 !important;

                    .tab-pane {
                        overflow: auto;

                        .py-2 {
                            padding-bottom: 0 !important;
                        }

                        &::-webkit-scrollbar {
                            width: 5px;
                            height: 8px;
                            background-color: #aaa;
                        }

                        &::-webkit-scrollbar-track {
                            border-radius: 0;
                        }
                    }
                }
            }

            .pdp-size-chart-table-customizable {
                width: 427px;

                table {
                    width: 200%;
                }
            }
        }
    }

    &__panel {
        min-height: 100vh;
        background: white;
        position: relative;
        border: 0;
        min-width: 45%;
        @include media-breakpoint-up(lg) {
            min-width: unset;
        }

        &::after {
            position: absolute;
            background-color: white;
            content: '';
            top: 0;
            left: 99%;
            height: 100%;
            width: 1000%;
        }
    }

    .modal {
        &-header {
            border: 0;

            svg {
                fill: $black;
            }

            .close {
                line-height: 0;
            }
        }

        &-dialog {
            padding: 0;
            transition: all 0.3s;
        }

        &-dialog {
            width: 100%;
            height: 100%;
            margin: 0;
            max-width: none;
        }

        &-footer {
            width: 100%;
            padding: 0;
            background: $white;

            // override default modal spacings
            > :not(:first-child) {
                margin-left: 0;
            }

            > :not(:last-child) {
                margin-right: 0;
            }
        }
    }

    // CCO-3277
    @include media-breakpoint-down(md) {
        &#unique-id-size-chart {
            overflow-y: hidden;

            &.modal.show .nb-modal-container {
                transform: translateY(20%);
            }

            .nb-modal-container {
                transform: translateY(100%);
                border-radius: 1rem 1rem 0 0;
                overflow: hidden;

                .nb-modal__panel {
                    min-height: 90vh;
                }

                .modal-body {
                    max-height: 74vh;
                    overflow-x: hidden;
                    overflow-y: auto;
                    padding-bottom: 2rem; //44px menu + 6px bottom spacing
                }
            }
        }
    }
}

.size-chart-container .mb-lg-14 {
    margin-bottom: 1rem !important;
}

.cancel-order-content {
    .order-heading,
    .order-email {
        margin-bottom: $nb-spacing-5;
    }

    .order-heading {
        font-weight: $fontweight-semibold;
    }

    .cancel-reason {
        margin-bottom: $nb-spacing-5;
    }

    .reasons-list {
        .custom-radio {
            .form-check-label::before {
                left: 0;
            }
        }
    }
}

#password-reset-form {
    .modal-dialog {
        min-width: rem(584);
        @include media-breakpoint-down(md) {
            min-width: 100%;
        }
    }

    .modal-body {
        @include media-breakpoint-up(md) {
            padding-top: rem(8);
        }
    }

    .modal-content {
        padding: 0;
        @include media-breakpoint-up(md) {
            padding: 0 0 0 rem(24);
        }
    }
}

.experience-commerce_assets-HeroComponent,
.experience-commerce_assets-textRightImageComponent,
.experience-commerce_assets-textLeftImageComponent {
    .nb-modal {
        overflow-x: hidden;
        overflow-y: hidden;
        background: $black;
    }

    .modal-dialog {
        height: 100vh;
        width: 100vw;
    }

    .modal-content {
        border: 0;
    }

    .modal-header {
        position: absolute;
        right: 0;
        top: 8%;
        z-index: 1050;
        background: transparent;
        @include media-breakpoint-up(sm) {
            top: 0;
        }
        @media only screen and (orientation: $portrait) and (max-width: $max) {
            top: 5%;
        }
        @media only screen and (orientation: $landscape) and (max-width: $max) {
            top: 7%;
        }

        svg {
            filter: invert(1);
            fill: $white;
        }
    }

    .modal-body {
        padding: 0;
    }

    .modal-footer {
        background: $black;
    }
}

.video-modal-open {
    @include media-breakpoint-down(md) {
        position: unset;
        -webkit-overflow-scrolling: touch;
    }
}

.shoe-finder-modal {
    min-width: 100%;
    padding: 3.5% 5%;

    .modal-content {
        height: 100%;
    }

    .modal-body {
        flex: 1 auto;
    }

    .shoe-finder-iframe {
        width: 100%;
        height: 100%;
        position: relative;
        display: block;
        border: none;
    }
    @include media-breakpoint-down(md) {
        padding: 0;
    }
}

#subscribe-global-modal.iPhone {
    // fixes bug with iPhone transitions and overflow
    overflow-y: visible;
    overflow-x: visible;

    input.form-control.subscriber-email {
        font-size: 16px; // 16px font is "standard" size and will not trigger page zoom when input is focused
    }
}

#unique-id-size-chart {
    .nb-modal__panel {
        padding-right: 0 !important;
        padding-left: 0 !important;

        .modal-body {
            padding: 14px;

            button {
                margin-top: 0.7rem !important;
                z-index: 9;
                right: 1rem;
            }
        }

        .how-to-measure {
            .description {
                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.modal.modal-v2 {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-modal;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    max-width: 100%;

    .modal-dialog {
        width: 620px;
        height: auto;
        max-width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: 0;
        transform: translate(-50%, -50%);
    }

    .modal-body {
        padding: 0;
    }

    .modal-content {
        border-radius: 4px;
        padding: 1.5rem;
    }

    .subtext.font-small {
        color: $gray-blue;
        font-size: $font-size14;
        line-height: 1;
        margin-top: 0.25rem;

        &::before {
            content: "*";
        }
    }

    button.close {
        top: 1.5rem;
        right: 1.5rem;
        z-index: 9;
        pointer-events: auto;
        position: absolute;
        border-radius: 4px;
        background-color: $ghost-white;
        opacity: 1;

        svg {
            margin: 9px;
            color: $nb-black;
        }
    }

    &:not(.delete) {

        @include media-breakpoint-down(sm) {
            overflow-y: hidden;

            &.modal.show .modal-dialog {
                right: 50%;
                left: 50%;
                transform: translate(-50%, 0);
            }

            .modal-dialog {
                bottom: 0;
                left: 50%;
                top: auto;
                transform: translate(-50%, 100%);

                .modal-body {
                    border-radius: 20px 20px 0 0;
                    overflow: hidden;
                    bottom: 0;
                    transform: translateY(0);
                }
            }

            .modal-content {
                padding: 1rem;
            }

            button.close {
                top: 1rem;
                right: 1rem;
            }
        }
    }
}

.modal-v2 {
    .edit-preferences {
        h4 {
            margin-bottom: 0;
            line-height: 2;
        }

        .card-form {
            .card-body {
                gap: 0;
            }
        }

        .nb-button-secondary {
            margin: 12px 8px 0 0;
            padding: 11px 16px;
            border: 1px solid transparent;

            &:hover {
                background-color: #e4e4e7;
            }

            &.selected-activity {
                border: 1px solid $nb-black;
            }
        }
    }
}

.modal.modal-v2.delete {
    .modal-dialog {
        width: 360px;
    }

    p {
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        font-weight: 700;
    }
}

// Address Form is too large for mobile and needs to scroll

div[data-action='Address-List'] {
    .modal.modal-v2 {
        overflow: scroll;

        &:not(.delete) {
            .modal-dialog {
                @media only screen and (max-height: $maxheight-800) {
                    top: 5vh;
                    transform: translate(-50%);
                }
            }
        }
        @include media-breakpoint-down(sm) {
            overflow-y: scroll;

            &:not(.delete) {
                .modal-dialog {
                    top: 25%;
                }
            }
        }
    }
}
