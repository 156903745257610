html[lang="ja"] {
    body {
        $font-family-japanese: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Helvetica Neue", "メイリオ", "Meiryo", "Helvetica", Helvetica, Arial, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

        &,
        * {
            font-family: $font-family-japanese !important;
        }
    }
}
