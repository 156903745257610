@import "~base/components/productTiles";

.experience-component {
    .slick-list {
        .image-container {
            overflow: initial;
        }

        .product-tile {
            padding: 2px;
        }
    }
}
