.mobile-homepage-quicklinks {
    .mobile-homepage-quicklinks-ctn {
        column-gap: 0.5rem;

        a.mobile-homepage-button {
            margin: 0 0 0.5rem;
            border-radius: 4px;
            background-color: #f2f2f3;
            border: 0.125rem solid #f2f2f3;
            line-height: 1.167;
            padding: 0.5rem 0.75rem;
            font-size: 0.75rem;
        }
    }
}
